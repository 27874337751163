<template>
    <div>
        <v-card elevation="0">
            <v-card-actions>
                <v-card-title>
                    Регламенты
                </v-card-title>
                <v-btn icon color="green" v-on:click="addRegulation">
                    <v-icon>
                        mdi-plus
                    </v-icon>
                </v-btn>
            </v-card-actions>
                <v-data-table v-if="regulations.length > 0" :items="regulations" :headers="regulationHeaders">
                    <template v-slot:item.actions="{item}">
                        <v-icon color="orange" v-if="selectedRegulationId !== item['@Regulation']" v-on:click="editRegulation(item)">mdi-pencil</v-icon>
                        <v-icon color="green" v-else v-on:click="updateRegulation(item)">mdi-check</v-icon>
                        <v-icon color="red" v-on:click="deleteRegulation(item)">mdi-delete</v-icon>
                    </template>
                    <template v-slot:item.InitStates="{item}">
                        <v-select v-model="item.InitStates"
                                    multiple
                                  :items="regulationStates"
                                  item-text="Name"
                                  item-value="@TaskState"
                                  style="width: 200px;"
                                      :readonly="selectedRegulationId !== item['@Regulation']"
                        ></v-select>
                    </template>
                    <template v-slot:item.Role="{item}">
                        <v-select
                                style="width: 200px;"
                                :items="roles" item-text="Name" item-value="@Role"
                                v-model="item['Role']"
                                :readonly="selectedRegulationId !== item['@Regulation']"
                        ></v-select>
                    </template>
                    <template v-slot:item.Name="{item}">
                        <p v-if="selectedRegulationId !== item['@Regulation']">{{item['Name']}}</p>
                        <v-text-field
                                v-else
                                v-model="item['Name']"
                        >
                        </v-text-field>

                    </template>
                </v-data-table>
                <template v-else>
                    <v-card-title>
                        Регламентов ещё нет. <v-btn color="white" class="elevation-0 mx-2" v-on:click="addRegulation">Добавить?</v-btn>
                    </v-card-title>
                </template>
        </v-card>
        <v-card elevation="0">
            <v-card-actions>
                <v-card-title>
                    Состояние регламента
                </v-card-title>
                <v-icon color="green" v-on:click="addRegulationState">mdi-plus</v-icon>
            </v-card-actions>
            <v-data-table :items="regulationStates" :headers="regulationStateHeaders">
                <template v-slot:item.actions="{item}">
                    <v-icon color="orange" v-if="selectedRegulationStateId !== item['@TaskState']" v-on:click="editTaskState(item)">mdi-pencil</v-icon>
                    <v-icon color="green" v-else v-on:click="updateTaskState(item)">mdi-check</v-icon>
                    <v-icon color="red" v-on:click="deleteTaskState(item)">mdi-delete</v-icon>
                </template>
                <template v-slot:item.Name="{item}">
                    <p v-if="selectedRegulationStateId !== item['@TaskState']">{{item.Name}}</p>
                    <v-text-field v-else v-model="item.Name"></v-text-field>
                </template>
                <template v-slot:item.TransitionCondition="{item}">
                    <p v-if="selectedRegulationStateId !== item['@TaskState']">{{item.TransitionCondition}}</p>
                    <v-text-field v-else v-model="item.TransitionCondition"></v-text-field>
                </template>
                <template v-slot:item.InProgressName="{item}">
                    <p v-if="selectedRegulationStateId !== item['@TaskState']">{{item.InProgressName}}</p>
                    <v-text-field v-else v-model="item.InProgressName"></v-text-field>
                </template>
                <template v-slot:item.Color="{item}">
                    <p v-if="selectedRegulationStateId !== item['@TaskState']"
                       :style="{backgroundColor: item.Color,
                    color: 'white'}"
                       class="text-center">{{item.Color}}
                    </p>
                    <template v-else>
                    <v-color-picker
                            v-model="item.Color"
                            :mode="mode"
                    ></v-color-picker>
                    <v-select
                            v-model="mode"
                            :items="modes"
                            style="max-width: 300px"
                    ></v-select>
                    </template>
                </template>
                <template v-slot:item.Transitions="{item}">
                    <v-select v-model="item.Transitions"
                              multiple
                              :items="regulationStates"
                              item-text="Name"
                              item-value="@TaskState"
                              style="width: 200px;"
                              :readonly="selectedRegulationStateId !== item['@TaskState']"
                    ></v-select>
                </template>
                <template v-slot:item.ExecutionRole="{item}">
                    <v-select v-model="item.ExecutionRole"
                              item-text="Name"
                              :items="roles"
                              item-value="@Role"
                              :readonly="selectedRegulationStateId !== item['@TaskState']"
                    ></v-select>
                </template>
                <template v-slot:item.Reassignable="{item}">
                    <v-checkbox v-model="item.Reassignable"
                              :readonly="selectedRegulationStateId !== item['@TaskState']"
                    ></v-checkbox>
                </template>
                <template v-slot:item.ReassignableToAuthor="{item}">
                    <v-checkbox v-model="item.ReassignableToAuthor"
                                :readonly="selectedRegulationStateId !== item['@TaskState']"
                    ></v-checkbox>
                </template>
                <template v-slot:item.Final="{item}">
                    <v-checkbox v-model="item.Final"
                                :readonly="selectedRegulationStateId !== item['@TaskState']"
                    ></v-checkbox>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
    import axios from 'axios';
    import {getURL} from "../../settings";

    export default {
        name: "Regulation",
        data: () => {
            return {
                regulations: [

                ],
                regulationHeaders: [
                    {
                        text: 'Действия',
                        value: 'actions'
                    },
                    {
                        text: 'Идентификатор',
                        value: '@Regulation'},
                    {
                        text: 'Начальные состояния',
                        value: 'InitStates'
                    },
                    {
                        text: 'Роль для создания',
                        value: 'Role'
                    },
                    {
                        text: 'Название',
                        value: 'Name'
                    }
                ],
                regulationStates: [

                ],
                regulationStateHeaders: [
                    {
                        value: 'actions',
                        text: 'Действия'
                    },
                    {
                        value: 'Name',
                        text: 'Название'
                    },
                    {
                        value: 'InProgressName',
                        text: 'Название на выполнении'
                    },
                    {
                        value: 'Transitions',
                        text: 'Переходы'
                    },
                    {
                      value: 'Color',
                      text: 'Цвет'
                    },
                    {
                        value: 'ExecutionRole',
                        text: 'Роль выполнения'
                    },
                    {
                        value: 'Reassignable',
                        text: 'Переназначаемый'
                    },
                    {
                        value: 'ReassignableToAuthor',
                        text: 'Вернуть автору'
                    },
                    {
                        value: 'Final',
                        text: 'Конечное состояние'
                    }
                ],
                regulationStateMap: {

                },
                selectedRegulationId: null,
                selectedRegulation: null,
                roles: [],
                rolesCache: {},
                TableMap: {
                    'actions': 'Действия',
                    '@Regulation': 'Идентификатор',
                    'InitStates': 'Начальные состояние',
                    'Name': 'Название',
                    'Role': 'Роль'
                },
                emptyRegulation: {
                    '@Regulation': -1,
                    'InitStates': [],
                    'Name': null,
                    'Role': null
                },
                selectedRegulationStateId: -1,
                emptyRegulationState: {
                    '@TaskState': -1,
                    'Name': null,
                    'TransitionCondition': null,
                    'InProgressName': null,
                    'Transitions': null,
                    'Regulation': null,
                    'ExecutionRole': null,
                    'Reassignable': null,
                    'ReassignableToAuthor': null
                },
                mode: 'hsla',
                modes: ['hsla', 'rgba', 'hexa'],
            }
        },
        mounted() {
            this.loadRoles();
            this.loadRegulations();
            this.loadRegulationStates();
        },
        methods: {
            loadRoles() {
                axios.get(getURL('admin/role/list')).then(res => {
                    this.roles = res.data;
                    this.roles.forEach(role => {
                        this.rolesCache[role['@Role']] = role['Name'];
                    })
                    console.log(this.rolesCache);
                })
            },
            addRegulation() {
                this.regulations.push(this.emptyRegulation);
                this.selectedRegulationId = -1;
            },
            addRegulationState() {
                this.regulationStates.push(this.emptyRegulationState);
                this.selectedRegulationStateId = -1;
            },
            editRegulation(regulation) {
                this.selectedRegulationId = regulation['@Regulation'];
            },
            updateRegulation(regulation) {
                regulation['action'] = regulation['@Regulation'] < 0 ? 'create' : 'update';

                axios.post(getURL('admin/docflow/regulation'), regulation).then(() => {
                    this.loadRegulations();
                    this.selectedRegulationId = null;
                })
            },
            loadRegulations() {
                axios.get(getURL('admin/docflow/regulation/list')).then(res => {
                    this.regulations = res.data;
                    if (this.regulations && this.regulations.length > 0) {
                        const resp_keys = Object.keys(this.regulations[0]);
                        for(const key of resp_keys) {
                            this.headers = Object.keys(this.regulations[0]).map(field => {return {text: this.TableMap[field], value: field}});
                            this.headers.push({text: this.TableMap[key] || key, value: key })
                        }
                    }
                })
            },
            deleteRegulation(regulation) {
                regulation['action'] = 'delete';
                axios.post(getURL('admin/docflow/regulation'), regulation).then(() => {
                    this.loadRegulations();
                })
            },
            editTaskState(item) {
                this.selectedRegulationStateId = item['@TaskState'];
            },
            updateTaskState(item) {
                item['action'] = item['@TaskState'] < 0 ? 'create' : 'update';
                console.log('color type: ', item.Color)
                if(typeof(item.Color) === "object")
                    item.Color= item.Color.hexa;
                axios.post(getURL('admin/docflow/task_states'), item).then(() => {
                    this.loadRegulationStates();
                    this.selectedRegulationStateId = null;
                })
            },
            deleteTaskState(item) {
                item['action'] = 'delete';
                axios.post(getURL('admin/docflow/task_states'), item).then(() => {
                    this.loadRegulationStates();
                    this.selectedRegulationStateId = null;
                });
            },
            loadRegulationStates() {
                axios.get(getURL('admin/docflow/task_states/list')).then(res => {
                    this.regulationStates = res.data;
                });
            }
        },
    }
</script>

<style scoped>

</style>
